body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --base-font-size: 18px;
  font-size: var(--base-font-size);
}

.modal-open {
  overflow-y: hidden;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ellipsis-3rows {
  word-wrap: break-word;
  -webkit-line-clamp: 3;
  overflow: hidden;
  line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.page-item {
  z-Index: 0;
}

a.user {
  text-decoration: none;
  font-weight: bold;
  color: #0d6efd;
}

img.userComment {
  width: 48px;
  height: auto;
  border-radius: 6px;
}

img.userPost {
  width: 64px;
  height: auto;
  border-radius: 6px;
}
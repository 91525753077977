.threedots:after {
    content: "\2807";
  }

.threedots-button {
  border: none;
  background-color: inherit;
  padding: 0px;
  cursor: pointer;
  display: inline-block;
}
.BaseTaskCard {
    position: relative;
    top: 0px;
    left: 0px;
}

.TaskCard {
    margin-bottom: 8px;
    padding-bottom: 4px;
    border-bottom: solid 1px #F0F0F0;
}

.ChildTaskCard {
    font-size: 0.8em;
    margin-bottom: 0px;
    padding-bottom: 5px;
    padding-left: 30px;
}

.TaskResolved {
    color: #BBB;
    text-decoration: line-through;
}

.TaskCheckbox {
    display: inline-block;
    padding-right: 5px;
}

a.Task {
    text-decoration: none;
    font-weight: normal;
    color: #063474;
}

a.BlockedTask {
    text-decoration: none;
    font-weight: normal;
    color: #bc7a7b;
}

a.ResolvedTask {
    text-decoration: line-through;
    font-weight: normal;
    color: #adb3bc;
}

.TaskListTimestamp {
    padding-right: 10px;
    color: #AAA;
    font-size: 10px;
}

.TaskListRightBlock {
    position: absolute;
    top: 0px;
    right: 4px;
    padding: 0px;
}

.PreLine {
    white-space: pre-line;
}

.FlexRow {
    display: flex;
    flex-direction: row;
}